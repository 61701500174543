<template>
  <v-card>
    <v-card-title class="text-h4 grey lighten-2">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <span class="me-2"> Chat Statistics </span>
        </v-col>
      </v-row>
    </v-card-title>

    <v-row class="mb-3 my-3">
      <v-card-actions>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          bottom
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Chat Statistic"
              :prepend-icon="icons.mdiCalendarMonth"
              :value="dateRangeText"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="dates" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="#ffeeee" @click="menu = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn text color="success" @click="getOpsStatistic(dates)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
        <v-icon class="ml-2" style="cursor: pointer" @click="resetDates">{{ icons.mdiClose }}</v-icon>
      </v-card-actions>
      <v-spacer></v-spacer>
    </v-row>
    <div v-if="isLoadingStats" class="loading-indicator">
      <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-if="!isLoadingStats">
      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>New {{ uniqData ? 'Contact' : 'Chat' }}</span>
          </v-card-title>
          <vue-apex-charts
            v-if="!uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticNew"
            :loading="isLoadingStats"
          ></vue-apex-charts>
          <vue-apex-charts
            v-if="uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticUniq"
            :loading="isLoadingStats"
          ></vue-apex-charts>
        </v-col>
      </v-row>
      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>Incoming {{ uniqData ? 'Contact' : 'Chat' }}</span>
          </v-card-title>
          <vue-apex-charts
            v-if="!uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticIncoming"
            :loading="isLoadingStats"
          ></vue-apex-charts>
          <vue-apex-charts
            v-if="uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticIncomingUniq"
            :loading="isLoadingStats"
          ></vue-apex-charts>
        </v-col>
      </v-row>
      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>Outgoing {{ uniqData ? 'Contact' : 'Chat' }}</span>
          </v-card-title>
          <vue-apex-charts
            v-if="!uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticOutcoming"
            :loading="isLoadingStats"
          ></vue-apex-charts>
          <vue-apex-charts
            v-if="uniqData"
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="messageStatisticOutcomingUniq"
            :loading="isLoadingStats"
          ></vue-apex-charts>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */
// import PouchDB from 'pouchdb-browser'
import {
  mdiCalendarMonth,
  mdiChartBar,
  mdiClose,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOutline,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlusCircle,
} from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

// PouchDB.adapter('worker', require('worker-pouch'))
export default {
  name: 'OperatorList',
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCalendarMonth,
        mdiClose,
      },
    }
  },
  data() {
    return {
      //chart
      uniqData: false,
      messageStatistic: [],
      messageStatisticNew: [],
      messageStatisticUniq: [],
      messageStatisticIncoming: [],
      messageStatisticOutcoming: [],
      messageStatisticIncomingUniq: [],
      messageStatisticOutcomingUniq: [],
      operatorStatistic: [],
      time: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21.00',
        '22.00',
        '23:00',
      ],
      totalDataOperator: [],
      incomingMessage: 0,
      outgoingMessage: 0,
      totalReplyDay: 0,
      dataStatisticDay: [],
      dataListChannel: [],
      dataListUser: [],
      listUserData: [],
      chartCreatedAt: {},
      userDataCreated: [],
      menu: false,
      rangeDates: [],
      dates: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],

      // DIALOG Error Message
      dialogErrorMessage: false,
      errorMessage: '',

      // Confirm delete User
      confirmDeleteMessage: false,
      deleteUserdata: {},

      //loading
      loading: false,

      // SETTING
      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: [],
      // DIALOG Add Operator
      dialogOperator: false,
      model: {
        operatorEmail: '',
      },
      alertText: '',

      dialogAlert: false,
      // Dialog Add Channel
      dialogSelectChannel: false,
      dataSubId: [],
      dataMenu: [],
      currentItem: {
        role: '',
        auto_assign: false,
        view_assign_only: false,
        limited_channel: false,
        enabled_channel: [],
        limited_menu: false,
        enabled_menu: [],
      },
      isLoadingStats: false,
      listInstances: [],
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   // console.log(inst, `INI INSTANCE`)
    //   if (inst) {
    //     return inst.value
    //   } else {
    //     return []
    //   }
    // },
    headers() {
      return [
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Average Reply Time', value: 'avg_reply_time', sortable: true, align: 'center' },
        { text: 'Total Replied', value: 'total_reply', sortable: true, align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    listMenu() {
      return this.user.package === 'basic'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
        : this.user.package === 'pro'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
        : this.user.package === 'business'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
        : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
    },
    viewAssign() {
      return this.currentItem.view_assign_only
    },
    limitedChannel() {
      return this.currentItem.limited_channel
    },
    limitedMenu() {
      return this.currentItem.limited_menu
    },
  },
  async mounted() {
    this.loading = true
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    this.getOpsStatistic()
  },
  watch: {
    viewAssign() {
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
        this.dataMenu = []
        this.dataSubId = []
      }
    },
    limitedChannel() {
      if (!this.currentItem.limited_channel) {
        this.dataSubId = []
      }
    },
    limitedMenu() {
      if (!this.currentItem.limited_menu) {
        this.dataMenu = []
      } else {
        if (this.currentItem.enabled_menu.length === 0) {
          this.dataMenu =
            this.user.package === 'basic'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
              : this.user.package === 'pro'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
              : this.user.package === 'business'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
              : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
        }
      }
    },
  },
  methods: {
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
    exportTableList() {
      // naive encoding to csv format
      const content = [this.headers.map(col => this.wrapCsvValue(col.text))]
        .concat(
          this.dataStatisticDay.map(row =>
            this.headers
              .map(col => {
                if (col.field === 'channel') {
                  return row.channel
                }
                if (col.field === 'statistic') {
                  return `"sent: ${row.statistic.sent === undefined ? '0' : row.statistic.sent} received: ${
                    row.statistic.received === undefined ? '0' : row.statistic.received
                  } read: ${row.statistic.read === undefined ? '0' : row.statistic.read} replied: ${
                    row.statistic.replied === undefined ? '0' : row.statistic.replied
                  }"`
                }

                return this.wrapCsvValue(row[col.value], undefined)
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = `${new Date()}-${'User Statistic'}.csv`
      anchor.click()
    },
    resetDates() {
      this.dates = [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      this.getOpsStatistic()
    },
    formatDate(date) {
      return `${new Date((new Date(date).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
        new Date((new Date(date).getTime() / 1000 + 72000) * 1000).getMonth() + 1
      }-${new Date((new Date(date).getTime() / 1000 + 72000) * 1000).getDate()}`
    },
    async getOpsStatistic() {
      this.isLoadingStats = true
      this.$refs.menu.save(this.dates)

      if (this.dates && this.dates.length === 2) {
        const startDate = new Date(this.dates[0])
        const endDate = new Date(this.dates[1])

        // Check if startDate is greater than endDate
        if (startDate.getTime() > endDate.getTime()) {
          this.rangeDates = this.getDateRange(endDate, startDate)
        } else {
          this.rangeDates = this.getDateRange(startDate, endDate)
        }
      } else {
        const centerDate = new Date(this.dates[0])
        // 72000 is equal to 20 hours in seconds
        const startDate = new Date((centerDate.getTime() / 1000 - 72000) * 1000)
        const endDate = new Date((centerDate.getTime() / 1000 + 72000) * 1000)

        // Formatting dates to year-month-date
        const formattedStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const formattedEndDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        this.rangeDates = this.getDateRange(new Date(formattedStartDate), new Date(formattedEndDate))
      }

      const uniqueDbRoom = (value, index, self) => {
        return self.indexOf(value) === index
      }

      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}statistic/fetch`

      const body = {
        instances: this.listInstances,
        sub_id: this.user.sub_id,
        rangeDates: this.rangeDates,
        dates: this.dates,
      }

      try {
        const { data: listStatistics } = await this.$axios.post(endpoint, body)
        if (listStatistics && listStatistics.status) {
          const chart = {
            chart: {
              stacked: true,
              toolbar: {
                show: true,
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                  customIcons: [],
                },
              },
            },
            colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1F979E'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            fill: {
              type: 'gradient',
              gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
            },
            xaxis: {
              categories: JSON.parse(JSON.stringify(this.rangeDates)),
            },
          }

          this.chartCreatedAt = chart

          this.messageStatisticIncoming = JSON.parse(JSON.stringify(listStatistics.data.seriesIncoming))

          this.messageStatisticOutcoming = JSON.parse(JSON.stringify(listStatistics.data.seriesOutcoming))

          this.messageStatisticNew = JSON.parse(JSON.stringify(listStatistics.data.seriesNewMsg))
        }
      } catch (error) {
        console.log(error, 'err fetch')
      }

      this.menu = false
      this.isLoadingStats = false
    },
    async getStatisticChart(date) {
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const statOps = await this.$store.dispatch('rooms/statisticOperator', {
        subId: this.user.sub_id,
        dateRange: this.rangeDates,
      })
      const chart = {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },

        colors: [
          // $themeColors.warning,
          // $themeColors.error,
          // $themeColors.secondary,
          // $themeColors.info,
          // $themeColors.success,

          '#ab7efd',
          '#F0D9FF',
          '#e0cffe',
          '#BFA2DB',
          '#b992fe',
          '#b391ec',
          '#b9a3dc',
          '#bdb5ca',
          '#bec6b9',
          '#8c62dd',
          '#966be8',
          '#a175f2',
          '#b588ff',
          '#c091ff',
          '#ca9bff',
          '#b089f3',
          '#b594e8',
          '#b89fde',
          '#bba9d4',
          '#bdb4c9',
          '#bebebe',
          '#bb93fe',
          '#caa8ff',
          '#d9beff',
          '#e6d3ff',
          '#f3e9ff',
          '#ffffff',
          '#8d68ce',
          '#6f53a1',
          '#533f76',
          '#392c4e',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 1,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          categories: [],
        },
      }
      // this.userDataCreated = JSON.parse(JSON.stringify(statOps.data.data ? statOps.data.data : []))
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart

      this.chartCreatedAt.xaxis.categories = range
      this.menu = false

      // console.log(statOps, 'aaaaaaaaa')
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // console.log(dates, 'oooooooooooo')
      return dates
    },
    async closeDialog() {
      this.dialogSelectChannel = false
      this.loading = true
      await this.$store
        .dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
        .then(data => {
          this.loading = false
        })
    },
    async saveChannel() {
      this.dialogSelectChannel = false
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
      }
      this.currentItem.enabled_channel = []
      if (this.currentItem.limited_channel) {
        this.dataSubId.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_channel.push(this.listInstances[index]._id)
          }
        })
      }
      this.currentItem.enabled_menu = []
      if (this.currentItem.limited_menu) {
        this.dataMenu.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_menu.push(this.listMenu[index])
          }
        })
      }
      var res = await this.$store.dispatch('auth/editUserSubscription', {
        id: this.currentItem.id,
        auto_assign: this.currentItem.auto_assign,
        view_assign_only: this.currentItem.view_assign_only,
        limited_channel: this.currentItem.limited_channel,
        enabled_channel: this.currentItem.enabled_channel,
        limited_menu: this.currentItem.limited_menu,
        enabled_menu: this.currentItem.enabled_menu,
      })
      if (!res.status) {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      await this.$store.dispatch('auth/fetchListUser', {
        sub_id: this.user.sub_id,
      })
      await this.$store.dispatch('auth/getChannelAccess', {
        sub_id: this.user.sub_id,
        email: this.user.email,
        token: this.user.token,
      })
    },
    getChannelsName(id) {
      const obj = this.listInstances.find(x => x._id === id)
      if (obj) {
        return (
          (obj.label.startsWith('-') || obj.label.includes('Default') || obj.label.includes('62')
            ? obj.label_server
            : obj.label) +
          ' (' +
          obj.phone_number +
          ')'
        )
      }
      return ''
    },
    async addSelectedChannel(item) {
      this.dialogSelectChannel = true
      this.currentItem = {
        ...item,
      }
      this.listInstances.forEach((instance, index) => {
        this.dataSubId[index] = item.enabled_channel && item.enabled_channel.findIndex(x => x === instance._id) > -1
      })
      this.listMenu.forEach((menu, index) => {
        this.dataMenu[index] = item.enabled_menu && item.enabled_menu.findIndex(x => x === menu) > -1
      })
    },
    async addOperator() {
      let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', this.user)
      // console.log(limitOps,"aaaaaaaaaaaaaaaaaa")
      if (this.dataListUsers.length < limitOps.data.data.dataValues.max_operator) {
        this.dialogOperator = true
      } else {
        this.showAlert(this.$t('warningmaxoperator'))
      }
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async saveUser() {
      if (this.validateEmail(this.model.operatorEmail)) {
        const currentWorkspace = this.listWorkspaces.find(
          workspace => workspace.role === 'Admin' && workspace.sub_id === this.user.sub_id,
        )
        console.log(currentWorkspace, this.listWorkspaces, `INI DIA`)
        var res = await this.$store.dispatch('auth/addUser', {
          email: this.model.operatorEmail,
          sub_id: this.user.sub_id,
          role: 'Operator',
          auto_assign: false,
          view_assign_only: false,
          limited_channel: false,
          enabled_channel: [],
          workspace_name: currentWorkspace.workspace_name,
        })
        if (res.status) {
          await this.$store.dispatch('auth/fetchListUser', {
            sub_id: this.user.sub_id,
          })
        } else {
          this.errorMessage = res.errorMessage
          this.dialogErrorMessage = true
        }
        this.model.operatorEmail = ''
        this.dialogOperator = false
      } else {
        this.errorMessage = 'Please Input in email Format'
        this.model.operatorEmail = ''
        this.dialogErrorMessage = true
        this.dialogOperator = false
      }
    },
    async deleteUser(data) {
      this.loading = true
      var res = await this.$store.dispatch('auth/deleteUser', {
        email: data.email,
        sub_id: data.sub_id,
      })
      if (res.status) {
        await this.$store.dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
      } else {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      this.loading = false
      this.deleteUserdata = {}
      this.confirmDeleteMessage = false
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 15px;
}

.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
